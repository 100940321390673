
<template>
  <ValidationObserver
    ref="userForm"
    v-slot="{ handleSubmit, failed}"
  >
    <b-toast
      :visible="failed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.password')"
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="playerData.password"
        ></b-form-input>
      </b-form-group>
      <div class="text-right">
        <b-overlay
          :show="isUpdatingPlayer"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="!playerId || !playerData.password"
          >
            {{ $t('buttons.save')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      playerData: {
        password: '',
      },
    }
  },
  computed: {
    ...mapState({
      isUpdatingPlayer: (state) => state.player.isUpdatingPlayer,
    }),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  methods: {
    ...mapActions(['updatePlayer']),
    setData() {
      this.playerData = {
        password: '',
      }
    },
    onSubmit() {
      this.updatePlayer({ playerId: this.playerId, data: this.playerData})
    },
  },
}
</script>
